import { Cookies } from "react-cookie-consent";


const gaProperty= 'UA-145356986-1'
const disableStr = 'ga-disable-' + gaProperty;

export const deleteAllCookies = () => {
  Object.keys(Cookies.get()).forEach(function(cookie) {
    if (cookie !== 'CookieConsent' && cookie !== disableStr) {
      Cookies.remove(cookie);
      Cookies.remove(cookie, { path: '/', domain: '.badmannersrecords.com' });
    }
  });
}

export const optOut= () => {
  deleteAllCookies()
  document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
  Cookies.set(disableStr , true, { expires: 365 });
  window[disableStr] = true;
}

export const useOptOut = () => {
  if (!Cookies.get('CookieConsent')) {
    optOut()
  } else {
    deleteAllCookies()
    window[disableStr] = true;
  }
}