/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { navigate, Link } from 'gatsby'

import CookieConsent, { Cookies } from "react-cookie-consent";
import { deleteAllCookies, optOut, useOptOut } from "../utils/analyticsDoNotTrack"

import "./layout.scss"
import "./cookieConsent.scss"
import "normalize.css/normalize.css"

const Layout = ({ children }) => {
  const [consentGiven, setConsent] = useState(true);

    useEffect( () => {
      setConsent(Cookies.get('CookieConsent'))
      if (consentGiven === false) {
        deleteAllCookies()
        useOptOut()
      }
    });
  const declineConsent = () => {
    optOut()
    redirectToDoNotTrack()
  }
  const redirectToDoNotTrack = () => {
    navigate("/do-not-track/")
  }
  const redirectToTrack = () => {
    navigate("/")
    console.log('BACK TO TRACK')
  }

  return (
    <>
      {/*<Header siteTitle={data.site.siteMetadata.title} />*/}
      <div>
        <main>{children}</main>
          <CookieConsent
            buttonText="Alright!"
            declineButtonText="I decline!"
            enableDeclineButton={true}
            onDecline={declineConsent}
            onAccept={redirectToTrack}
            disableStyles={true}
          >
            We use cookies to enhance your experience. <Link className="info-link" to='/do-not-track/dataSecurity'>Data policy</Link> <Link className="info-link" to='/do-not-track/imprint'>Imprint</Link>
          </CookieConsent>
          <footer>
            {/*© {new Date().getFullYear()}, Built with*/}
            {/*{` `}*/}
            {/*<a href="https://www.gatsbyjs.org">Gatsby</a>*/}
          </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
