/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

import logo from '../images/bm-icon-red-black.png'
// import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  // const { site } = useStaticQuery(
  //   graphql`
  //     query {
  //       site {
  //         siteMetadata {
  //           title
  //           description
  //           author
  //         }
  //       }
  //     }
  //   `
  // )

  const metaDescription = "BAD MANNERS records, Berlin"
  const defaultPicture = logo

  return (
    <Helmet
      script={[{
        type: 'text/javascript',
        innerHTML: '// Set to the same value as the web property used on the site\n' +
          'var gaProperty = \'UA-145356986-1\';\n' +
          'console.log(\'checking analytics\')' +
          '\n' +
          '// Disable tracking if the opt-out cookie exists.\n' +
          'var disableStr = \'ga-disable-\' + gaProperty;\n' +
          'if (document.cookie.indexOf(disableStr + \'=true\') > -1) {\n' +
          '  window[disableStr] = true;\n' +
          'console.log(\'disabled analytics\')' +
          '}'
      }]}
      htmlAttributes={{
        lang,
      }}
      title={title}
      // titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:image`,
          content: defaultPicture,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          // content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:image`,
          content: defaultPicture,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  title: 'BAD MANNERS records',
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
